//@flow
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import type { AppProtectionTask } from '@dt/user-api/mobile_apps';
import Header from '@dt/components/src/Header';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { fontFamilies, fontSizes, palette } from '@dt/theme';
import AppProtectionTaskCard from './AppProtectionTaskCard';
import type { BadgeTypeEnum } from '@dt/components';
import ProtectionBadge from '@dt/components/src/app-protection-badges/Badge';
import { getBadgeDescriptionFromBadgeType } from '@dt/components';

const useStyles = makeStyles({
  content: {
    marginTop: 50,
    padding: '10px 80px',
    backgroundColor: palette.tableDark,
    fontFamily: fontFamilies.primary,
    paddingTop: 50,
    paddingBottom: 50,
  },
  title: {
    textAlign: 'center',
  },
  badge: {
    width: 83,
    height: 83,
    margin: '0 auto',
  },
  description: {
    marginTop: 10,
    marginBottom: 40,
    textAlign: 'center',
  },
  dialogSectionTitle: {
    display: 'inline-block',
    margin: 0,
    marginBottom: 10,
    fontSize: fontSizes.medium,
  },
  dialogSectionSubtitle: {
    display: 'inline-block',
    fontSize: fontSizes.medium,
    float: 'right',
  },
  buttonIcon: {
    marginLeft: 4,
  },
});

type Props = {|
  enabled: boolean,
  onClose: () => void,
  badgeType: BadgeTypeEnum,
  tasksForBadge: $ReadOnlyArray<AppProtectionTask>,
|};

export default memo<Props>(function AppProtectionBadgeTasksDialog({
  tasksForBadge,
  onClose,
  badgeType,
  enabled,
}) {
  const classes = useStyles();
  const description = badgeType && getBadgeDescriptionFromBadgeType(badgeType);

  return (
    <>
      <Header
        style={{
          wrapper: {
            backgroundColor: palette.darkBg,
            color: palette.inacitveColor,
            justifyContent: 'space-between',
            paddingLeft: 10,
          },
        }}
        secondaryNavigationItems={
          <Button variant="outlined" onClick={onClose}>
            Close <CloseIcon className={classes.buttonIcon} />
          </Button>
        }
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>{}</div>
      </Header>
      <div className={classes.content}>
        <h2 className={classes.title}>
          {tasksForBadge.length > 1 ? 'Tasks ' : 'Task '}
          {'For Badge'}
        </h2>

        <div>
          <div className={classes.badge}>
            <ProtectionBadge type={badgeType} enabled={enabled} />
          </div>

          <div className={classes.description}>{description}</div>
        </div>

        {tasksForBadge.map((task, i) => (
          <div key={task.issue_type_id} style={{ marginBottom: 10 }}>
            {i === 0 && enabled && (
              <div>
                <span className={classes.dialogSectionTitle}>Completed</span>
                <span className={classes.dialogSectionSubtitle}>
                  Included in score
                </span>
              </div>
            )}
            {i === 0 && !enabled && task.security_finding_id && (
              <div>
                <span className={classes.dialogSectionTitle}>Incomplete</span>
                <span className={classes.dialogSectionSubtitle}>
                  Complete to earn badge
                </span>
              </div>
            )}
            {i === 0 && !enabled && !task.security_finding_id && (
              <div>
                <span className={classes.dialogSectionTitle}>
                  Not Applicable
                </span>
              </div>
            )}
            <AppProtectionTaskCard key={task.issue_type_id} task={task} />
          </div>
        ))}
      </div>
    </>
  );
});
